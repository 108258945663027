/* App.css */

body {
  background: -webkit-linear-gradient(135deg, #3498db, #8e44ad);
  background: linear-gradient(135deg, #3498db, #8e44ad);
  font-family: 'Poppins', sans-serif;
  color: #333;
  padding: 20px;
}

.section {
  padding: 20px;
  margin-bottom: 30px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Pour contenir les éléments flottants */
}

.course-list-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.course-column {
  width: 48%;
}

.course-item {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.course-item:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.course-item-available {
  background-color: #e8f5e9;
  border-left: 5px solid #4caf50;
}

.course-item-reserved {
  background-color: #ffebee;
  border-left: 5px solid #f44336;
  pointer-events: none; /* Désactiver le clic sur les cours réservés */
}

.course-item h3 {
  margin-top: 0;
  color: #2c3e50;
}

.course-item p {
  margin: 5px 0;
  font-size: 0.9em;
}

.course-item .status {
  font-weight: bold;
  text-align: right;
  margin-top: 10px;
  color: #2e7d32;
}

.wp-block-image.alignright {
  float: right;
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.wp-block-image img {
  max-width: 150px;
  height: auto;
}

.title-container {
  margin-bottom: 20px;
  overflow: hidden; /* Empêche le contenu de passer sous l'image */
}

h1.wp-block-heading,
h2.wp-block-heading {
  overflow: hidden; /* Empêche le texte de passer sous l'image */
}

.wp-block-heading {
  font-size: 24px;
  font-weight: bold;
}

.wp-image-103 {
  width: 100px;
  margin-bottom: 20px;
}

.wp-block-image {
  float: right;
  margin-left: 20px;
}

/* Styles pour le formulaire modal */
.popup-form label {
  display: block;
  margin-bottom: 5px;
}

.popup-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-form button {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-form button:hover {
  background-color: #2980b9;
}

/* Styles pour les messages de confirmation */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

/* Styles pour le spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .course-list-container {
    flex-direction: column;
  }

  .course-column {
    width: 100%;
  }

  .wp-block-image.alignright {
    float: none;
    margin: 1em auto;
    text-align: center;
  }
}

.course-dates {
  margin-bottom: 10px;
}

.course-dates p {
  margin: 0;
  font-weight: bold;
  color: #0056b3;
}

.grouped-courses-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.date-box {
  padding: 20px;
  border: 2px solid #0056b3;
  border-radius: 10px;
  background-color: #e3f2fd;
}

.date-title {
  font-size: 22px;
  margin-bottom: 15px;
}

.courses-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.course-columns {
  display: flex;
  justify-content: space-between;
}

.course-column {
  flex: 1;
  margin-right: 20px;
}

.course-column ul {
  list-style-type: none;
  padding: 0;
}
